import styled from 'styled-components';
import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { useLocation } from 'react-router-dom';

type TypeId = 'TYPE1' | 'TYPE2' | 'TYPE3' | 'TYPE4' | 'TYPE5';

const Wrapper = styled.div<{ top: number }>`
  width: 100%;
  overflow-x: hidden;
  position: absolute;
  z-index: 0;
  left: 0;
  top: ${(props) => props.top + 'px'};
`;

const Polyline = styled.polyline`
  fill: transparent;
  stroke: #e60020;
  stroke-width: 6;
`;

let sidebar = 240; // Width of sidebar
const MAIN = 1096; // Width of main part
const BORDER = 6;
const WIDTH = 9999; // This number should be big enough to cover the whole screen

const GeneratedLine = () => {
  // space is space between sidebar and main container, minimus 50px
  // space = window width - main width - sidebar width
  const calculateSpace = (): number => {
    const { innerWidth } = window;
    sidebar = innerWidth > 1200 && innerWidth < 1400 ? 60 : 240;
    return Math.max((window.innerWidth - 1096 - sidebar) / 2, 50);
  };

  const [space, setSpace] = useState(calculateSpace());

  useEffect(() => {
    const handleResize = () => {
      setSpace(calculateSpace());
    };
    window.addEventListener('resize', debounce(handleResize, 300));
  });

  const { pathname } = useLocation();
  let height = 60;
  let top = 80;
  let diff = 10;
  let typeId: TypeId = 'TYPE1';
  const isChannelSearchPage = pathname === '/';
  const isVideoSearchPage = pathname === '/video';
  const isChannelDetailsPage = pathname.startsWith('/channel_details/');
  const isVideoDetailsPage = pathname.startsWith('/video_details/');
  const isTermsOfServicePage = pathname === '/term_of_service';
  const isPrivacyPolicyPage = pathname === '/privacy_policy';
  const isContactUsPage = pathname.startsWith('/contact');
  const isReportPage = pathname.startsWith('/report/');
  const isMyPage = pathname.startsWith('/mypage');

  /**
   * heigh: The whole height of the line
   * top: The line svg top value (relate to window)
   * TypeId: 3 types for 3 kinds of pages
   * TODO:
   * Here `height` and `top` need to be adjusted when all the other styles are fixed.
   * Because it need to match the postion of sidebar or search input etc.
   */
  switch (true) {
    case isChannelSearchPage:
      height = 60;
      top = 132;
      typeId = 'TYPE1';
      break;
    case isChannelDetailsPage:
      height = 20;
      top = 114;
      typeId = 'TYPE2';
      break;
    case isVideoSearchPage:
      height = 80;
      top = 106;
      typeId = 'TYPE3';
      break;
    case isVideoDetailsPage:
      height = 400;
      top = 185;
      typeId = 'TYPE1';
      break;
    case isTermsOfServicePage:
      height = 340;
      top = 145;
      diff = 235;
      typeId = 'TYPE4';
      break;
    case isPrivacyPolicyPage:
      height = 290;
      top = 145;
      diff = 145;
      typeId = 'TYPE4';
      break;
    case isContactUsPage:
      height = 220;
      top = 106;
      typeId = 'TYPE3';
      break;
    case isReportPage:
      height = 320;
      top = 106;
      typeId = 'TYPE1';
      break;
    case isMyPage:
      height = 300;
      top = 100;
      typeId = 'TYPE5';
      break;
    default:
      height = 0;
  }

  const heightTotal = height + BORDER * 2;
  const lines = {
    /* 
    *----|
         |
         |-----------------
    */
    TYPE1: `
      0,${BORDER} 
      ${sidebar + space * 0.5},${BORDER} 
      ${sidebar + space * 0.5},${heightTotal - BORDER} 
      ${WIDTH},${heightTotal}
    `,
    /* 
    *     |------------------
          |
      ----|    
    */
    TYPE2: `
      0,${heightTotal - BORDER} 
      ${sidebar + space * 0.5},${heightTotal - BORDER} 
      ${sidebar + space * 0.5},${BORDER}
      ${WIDTH},${heightTotal}
    `,
    /* 
    *     |------------------|
          |                  |
      ----|                  |-----
    */
    TYPE3: `
      0,${heightTotal - BORDER} 
      ${sidebar + space * 0.5},${heightTotal - BORDER} 
      ${sidebar + space * 0.5},${BORDER}
      ${sidebar + space * 1.5 + MAIN},${BORDER}
      ${sidebar + space * 1.5 + MAIN},${heightTotal - BORDER}
      ${WIDTH},${heightTotal}
    `,
    /* 
    *     |------------------|
          |                  |
      ----|                  |
                             | 
                             |-----
      この落差をdiffと置いています
    */
    TYPE4: `
      0,${heightTotal - BORDER - diff} 
      ${sidebar + space * 0.5},${heightTotal - BORDER - diff} 
      ${sidebar + space * 0.5},${BORDER}
      ${sidebar + space * 1.5 + MAIN},${BORDER}
      ${sidebar + space * 1.5 + MAIN},${heightTotal - BORDER}
      ${WIDTH},${heightTotal}
    `,
    /* 
    *                       |
    *                       |
    *     |-----------------|
          |                  
      ----|                  
    */
    TYPE5: `
      0,${heightTotal - BORDER - diff} 
      ${sidebar + space * 0.5},${heightTotal - BORDER - diff} 
      ${sidebar + space * 0.5},${BORDER}
      ${sidebar + space * 1.5 + MAIN},${BORDER}
      ${sidebar + space * 1.5 + MAIN}, -1000
      // ${sidebar + space * 1.5 + MAIN},${heightTotal - BORDER}
      // ${WIDTH}, -1000
    `,
  };

  return height > 0 ? (
    <Wrapper top={top}>
      <svg height={heightTotal} width={WIDTH}>
        <Polyline points={lines[typeId]} />
      </svg>
    </Wrapper>
  ) : (
    <></>
  );
};

const BackgroundLine = () => {
  return <GeneratedLine />;
};

export default BackgroundLine;
