import { useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useUserInfoStore } from '../store/UserInfo';

const AuthRequiredRoute = (props: RouteProps) => {
  const { fetchUserInfo } = useUserInfoStore((store) => ({
    fetchUserInfo: store.fetchUserInfo,
  }));
  // /login ではチェックしない
  const [checked, setChecked] = useState(
    !!props.location?.pathname?.includes('login'),
  );
  const [loginRequire, setLoginRequire] = useState(false);

  useEffect(() => {
    if (!checked) {
      fetchUserInfo()
        .catch(() => {
          setLoginRequire(true);
        })
        .finally(() => {
          setChecked(true);
        });
    }
  }, [checked, fetchUserInfo]);

  return loginRequire ? <Redirect to="/login" /> : <Route {...props} />;
};

export default AuthRequiredRoute;
