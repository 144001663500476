import { useMediaQuery } from '@material-ui/core';

type MediaType = 'pc' | 'mobile' | 'tablet';

export const mediaPC = '@media (min-width: 521px)';
export const mediaMobile = '@media (max-width: 520px)';
export const mediaTablet = '@media (min-width: 1200px) and (max-width: 1399px)';

export const useDevice = (type: MediaType) => {
  const matches = useMediaQuery('(max-width: 520px)');
  if (type == 'mobile') {
    return matches;
  }
  return !matches;
};
