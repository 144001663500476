import axios from 'axios';
import { API_BASE_URL } from '../consts';

const apiRequestClient = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

apiRequestClient.defaults.withCredentials = true;

export default apiRequestClient;
