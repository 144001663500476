import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { mediaMobile, mediaPC } from '../../lib/MediaQuery';
import { Empty } from '../../types/Utility';

export const Mobile = ({ children }: PropsWithChildren<Empty>) => (
  <MobileContainer className="mobile-container">{children}</MobileContainer>
);

export const PC = ({ children }: PropsWithChildren<Empty>) => (
  <PCContainer className="pc-container">{children}</PCContainer>
);

const MobileContainer = styled.div`
  ${mediaMobile} {
    display: inherit;
  }

  ${mediaPC} {
    display: none;
  }
`;

const PCContainer = styled.div`
  ${mediaPC} {
    display: inherit;
  }

  ${mediaMobile} {
    display: none;
  }
`;
