import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { MenuIcon } from './SVG';

type Props = {
  onClick: () => void;
};

const MenuButton = ({ onClick }: Props) => (
  <Button onClick={onClick}>
    <Menu />
  </Button>
);

const Menu = styled(MenuIcon)`
  padding: 5px;
`;

export default MenuButton;
