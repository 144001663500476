import { CircularProgress } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import './App.css';
import { PC } from './components/Common/MediaQuery';
import { SideMenu } from './components/Header/DrawerMenu';
import Header from './components/Header/Header';
import BackgroundLine from './components/Common/BackgroundLine';
import usePageTitle, { PageTitleProvider } from './hooks/usePageTitle';
import AuthRequiredRoute from './lib/AuthRequiredRoute';
import { mediaMobile, mediaPC, mediaTablet } from './lib/MediaQuery';
import ScrollToTop from './components/Common/ScrollToTop';

const Login = lazy(() => import('./views/Login'));
const ChannelReport = lazy(() => import('./views/ChannelReport'));
const ChannelSearch = lazy(() => import('./views/ChannelSearch'));
const VideoSearch = lazy(() => import('./views/VideoSearch'));
const ChannelDetails = lazy(() => import('./views/ChannelDetails'));
const NotFound = lazy(() => import('./views/NotFound'));
const VideoDetails = lazy(() => import('./views/VideoDetails'));
const ContactUs = lazy(() => import('./views/ContactUs'));
const TermsOfServicePage = lazy(() => import('./views/TermsOfServicePage'));
const PrivacyPolicyPage = lazy(() => import('./views/PrivacyPolicyPage'));
const MyPage = lazy(() => import('./views/MyPage'));

function App() {
  const { contextValue } = usePageTitle();
  return (
    <PageTitleProvider value={contextValue}>
      <StylesProvider injectFirst>
        <BrowserRouter>
          <ScrollToTop />
          <HeaderContainer>
            <Header />
          </HeaderContainer>
          <div className="App">
            <PC>
              <BackgroundLine />
              <div style={{ height: '100vh' }}>
                <SideMenuContainer>
                  <SideMenu />
                </SideMenuContainer>
              </div>
            </PC>
            <AppContainer>
              <Suspense fallback={<CircularProgress />}>
                <Switch>
                  <AuthRequiredRoute path="/" exact component={ChannelSearch} />
                  <AuthRequiredRoute
                    path="/report/channel/:type"
                    exact
                    component={ChannelReport}
                  />
                  <AuthRequiredRoute
                    path="/video"
                    exact
                    component={VideoSearch}
                  />
                  <AuthRequiredRoute
                    path="/channel_details/:id"
                    component={ChannelDetails}
                  />
                  <Route path="/login" component={Login} />
                  <AuthRequiredRoute
                    path="/video_details/:videoId"
                    component={VideoDetails}
                  />
                  <AuthRequiredRoute path="/contact" component={ContactUs} />

                  <Route path="/error/404" component={NotFound} />
                  <Route
                    path="/term_of_service"
                    component={TermsOfServicePage}
                  />
                  <Route path="/privacy_policy" component={PrivacyPolicyPage} />
                  <AuthRequiredRoute path="/mypage" component={MyPage} />
                </Switch>
              </Suspense>
            </AppContainer>
          </div>
        </BrowserRouter>
      </StylesProvider>
    </PageTitleProvider>
  );
}

const HeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 1000;
  margin-top: -10px;
  padding-top: 10px;
  ${mediaPC} {
    height: 56px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
  ${mediaMobile} {
    height: 60px;
  }
`;

const SideMenuContainer = styled.div`
  background-color: #fff;
  width: 240px;
  height: 100%;
  padding-top: 20px;
  margin-top: 56px;
  position: fixed;
  ${mediaTablet} {
    width: 60px;
  }
`;

const AppContainer = styled.div`
  flex-grow: 1;
  ${mediaPC} {
    display: flex;
    position: relative;
    min-width: 1096px;
    overflow: hidden;
    padding: 20px 50px;
    margin: 56px 0px 0px 240px;
    overflow-x: auto;
    justify-content: center;
  }
  ${mediaMobile} {
    overflow: hidden;
    padding-top: 60px;
    height: 100%;
  }
  ${mediaTablet} {
    margin: 56px 0px 0px 60px;
  }
`;

export default App;
