import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Avatar as MUAvatar } from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { AuthApi } from '../../api/authApi';
import { useUserInfoStore } from '../../store/UserInfo';
import { Logout } from '../Common/SVG';

const StyledMenu = withStyles({
  paper: {
    minWidth: '240px',
    maxWidth: '60vw',
    marginTop: '10px',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:hover': {
      backgroundColor: '#E5E5E5',
    },
  },
}))(MenuItem);

const Setting = () => {
  const {
    isLogin,
    profileImg,
    channelTitle,
    channelImg,
    userChannelId,
    fetchUserChannelDetails,
  } = useUserInfoStore((store) => ({
    isLogin: store.isLogin,
    profileImg: store.profileImg,
    channelTitle: store.channelTitle,
    channelImg: store.channelImg,
    userChannelId: store.channelId,
    fetchUserChannelDetails: store.fetchUserChannelDetails,
  }));
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    fetchUserChannelDetails(userChannelId);
  }, [userChannelId]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChannelChange = () => {
    setAnchorEl(null);
    history.replace('/mypage');
  };

  const handleLogout = async () => {
    await AuthApi.logout();
    setAnchorEl(null);
    history.replace('/login');
  };

  return isLogin ? (
    <>
      <AvatarContainer>
        <Avatar src={profileImg} onClick={handleClick} />
      </AvatarContainer>
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <StyledMenuItem onClick={handleChannelChange}>
          <Avatar src={channelImg} />
          {channelTitle}
        </StyledMenuItem> */}
        <StyledMenuItem onClick={handleLogout}>
          <LogoutIcon />
          ログアウト
        </StyledMenuItem>
      </StyledMenu>
    </>
  ) : null;
};

export default Setting;

const AvatarContainer = styled.div`
  margin-left: auto;
  padding: 5px;
`;

const Avatar = styled(MUAvatar)`
  width: 40px;
  border: 1px solid #ddd;
  margin-right: 10px;
`;
const LogoutIcon = styled(Logout)`
  margin-right: 5px;
`;
