import { useObserver } from 'mobx-react-lite';
import { Context, useContext } from 'react';

export type Selector<TStore, TSelection> = (store: TStore) => TSelection;

export function useStore<TStore, TSelection>(
  context: Context<TStore>,
  selector: Selector<TStore, TSelection>,
): TSelection {
  const store = useContext(context);
  if (!store) {
    throw new Error('need to pass a value to the context');
  }

  return useObserver(() => selector(store));
}
