import { AxiosResponse } from 'axios';
import apiRequestClient from './apiClient';

class AuthApiClient {
  async logout(): Promise<AxiosResponse<void>> {
    return await apiRequestClient.post('/users/logout', {});
  }
}

export const AuthApi = new AuthApiClient();
