import { Avatar as MUAvatar, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import usePageTitle from '../../hooks/usePageTitle';
import BackButton from '../Common/BackButton';
import { Mobile, PC } from '../Common/MediaQuery';
import MenuButton from '../Common/MenuButton';
import { LogoIcon, BetaVersion } from '../Common/SVG';
import { DrawerMenu } from '../Header/DrawerMenu';
import { Link } from 'react-router-dom';
import Setting from './Setting';

const Header = () => {
  const { title, headerTitle } = usePageTitle();
  const showBackButton =
    location.pathname.indexOf('/channel_details/') === 0 ||
    location.pathname.indexOf('/video_details/') === 0;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <Wrapper>
      <Helmet>
        <title>tube me - {title}</title>
      </Helmet>
      <PC>
        <LogoContainer>
          <Link to="/">
            <Logo />
          </Link>
          <Beta />
        </LogoContainer>
      </PC>
      <Mobile>
        <Container>
          {showBackButton ? (
            <BackButton />
          ) : (
            <>
              <MenuButton onClick={() => setIsDrawerOpen(true)} />
              <DrawerMenu
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
              />
            </>
          )}

          <PageName>{headerTitle}</PageName>
        </Container>
      </Mobile>
      <Profile>
        <Setting />
      </Profile>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div`
  position: relative;
`;

const Container = styled.header`
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  padding: 3px 3px;
  align-items: center;
  border-bottom: 2px solid #efefef;
  height: 54px;
`;

const Beta = styled(BetaVersion)`
  height: 68px;
`;

const Logo = styled(LogoIcon)`
  height: 50px;
  width: 151px;
  height: 56px;
`;

const LogoContainer = styled.div`
  display: flex;
  height: 56px;
  padding-left: 20px;
`;

const PageName = styled(Typography).attrs(() => ({
  variant: 'h5',
}))`
  padding: 5px;
  font-size: 18px;
  line-height: 18px;
  font-family: 'Noto Sans JP';
`;

const Profile = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
`;
