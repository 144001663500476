import { Box, Drawer, Divider } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import {
  LogoIcon,
  ReportActive,
  Channel,
  BetaVersion,
  MovieActive,
} from '../Common/SVG';
import styled from 'styled-components';
import { Mobile } from '../Common/MediaQuery';
import { mediaMobile, mediaPC, mediaTablet } from '../../lib/MediaQuery';

type Props = {
  isOpen: boolean;
  onClose?: () => void;
};

export const DrawerMenu = ({ isOpen, onClose }: Props) => (
  <Drawer open={isOpen} onClose={onClose}>
    <SideMenu />
  </Drawer>
);

const sideBar = [
  {
    to: '/report/channel/me',
    match: /^\/report\//,
    name: 'レポート',
    icon: (() => {
      return <ReportActive />;
    })(),
    subs: [
      {
        to: '/report/channel/me',
        name: 'チャネルレポート',
      },
    ],
  },
  {
    to: '/',
    name: 'チャンネル検索',
    icon: (() => {
      return <Channel />;
    })(),
  },
  {
    to: '/video',
    name: '動画検索',
    icon: (() => {
      return <MovieActive />;
    })(),
  },
];

export const SideMenu = () => {
  const location = useLocation();

  const isActive = (to: string, match?: RegExp | null) => {
    const { pathname } = location;
    return pathname === to || (match && pathname.match(match));
  };

  return (
    <SideMenuContainer>
      <Mobile>
        <Logo>
          <LogoSize />
          <Beta />
        </Logo>
      </Mobile>
      <div>
        {sideBar.map((item) => {
          return (
            <MenuBlock
              className={isActive(item.to, item.match) ? 'active' : ''}
              key={item.name}
            >
              <MenuTop>
                <MenuIcon>{item.icon}</MenuIcon>
                <MenuText to={item.to}>{item.name}</MenuText>
              </MenuTop>
              {item.subs ? (
                <MenuSub>
                  {item.subs.map((sub) => {
                    return (
                      <MenuSubText
                        className={isActive(sub.to) ? 'active-sub' : ''}
                        key={sub.name}
                        to={sub.to}
                      >
                        {sub.name}
                      </MenuSubText>
                    );
                  })}
                </MenuSub>
              ) : null}
            </MenuBlock>
          );
        })}
      </div>
      <Divider />
      <MenuBottom>
        <SideMenuLink to={`/term_of_service`}>
          <MenuBottomText>
            <MenuTextColorGray>利用規約</MenuTextColorGray>
          </MenuBottomText>
        </SideMenuLink>
        <SideMenuLink to={`/privacy_policy`}>
          <MenuBottomText>
            <MenuTextColorGray>プライバシーポリシー</MenuTextColorGray>
          </MenuBottomText>
        </SideMenuLink>
        <SideMenuLink to={`/contact`}>
          <MenuTextColorGray>お問い合わせ</MenuTextColorGray>
        </SideMenuLink>
      </MenuBottom>
    </SideMenuContainer>
  );
};

const SideMenuContainer = styled.div`
  background-color: #fff;
  ${mediaPC} {
    width: 240px;
  }

  ${mediaMobile} {
    width: 250px;
  }

  ${mediaTablet} {
    width: 60px;
    .hamburger-center {
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const SideMenuLink = styled(Link)`
  text-decoration: none;
`;

const MenuIcon = styled.div`
  filter: grayscale(1);
  opacity: 0.4;
`;

const MenuSubText = styled(Link)`
  display: block;
  text-align: left;
  margin: 15px 0 0 28px;
  color: #909090 !important;
  &.active-sub {
    color: #e60020 !important;
  }
  ${mediaTablet} {
    display: none;
  }
`;

const MenuSub = styled.ul`
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 12px;
  a {
    color: #909090;
  }
`;

const MenuBlock = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
  font-size: 14px;
  align-items: center;
  ${MenuSub} {
    display: none;
  }
  a {
    text-decoration: none;
  }
  &.active {
    ${MenuSub} {
      display: block;
    }
    a {
      color: #3e3a39;
    }
    ${MenuIcon} {
      opacity: 1;
      filter: grayscale(0);
    }
  }
`;
const MenuTop = styled.div`
  display: flex;
  ${mediaTablet} {
    display: block;
  }
`;

const MenuText = styled(Link)`
  margin-left: 8px;
  color: #909090;
  text-decoration: none;
  ${mediaTablet} {
    display: none;
  }
`;

const MenuTextColorGray = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  color: #909090;
  font-size: 12px;
  ${mediaTablet} {
    display: none;
  }
`;

const MenuBottom = styled.div`
  margin-top: 16px;
  a {
    text-decoration: none;
  }
`;

const MenuBottomText = styled.div`
  margin-bottom: 16px;
`;

const LogoSize = styled(LogoIcon)`
  margin-left: auto;
  margin-right: auto;
  width: 50%;
`;

const Logo = styled.div`
  margin: 20px auto 16px;
  width: 85%;
`;

const Beta = styled(BetaVersion)`
  height: 52px;
`;
