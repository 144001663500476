import apiRequestClient from '../apiClient';
import {
  FetchChannelDetailsResponse,
  FetchChannelDetailsVideoRankingResponse,
  FetchChannelDetailsTransitionResponse,
  ChannelSearchListingResult,
} from './channelDetails.types';

export async function fetchChannelKpi(channelId: string) {
  const { data } = await apiRequestClient.get(
    `channel_details/kpi/${channelId}`,
  );

  return data as FetchChannelDetailsResponse;
}

export async function fetchChannelVideoRanking(channelId: string) {
  const { data } = await apiRequestClient.get(
    `channel_details/ranking/${channelId}`,
  );

  return data as FetchChannelDetailsVideoRankingResponse;
}

export async function fetchChannelDetailsTransition(
  channelId: string,
  start: string,
  end: string,
) {
  const { data } = await apiRequestClient.get(
    `channel_details/changes/${channelId}/?start=${start}&end=${end}`,
  );

  return data as FetchChannelDetailsTransitionResponse;
}

export async function fetchChannelDetailsMostChangePeriodTransition(
  channelId: string,
  type: string,
) {
  const { data } = await apiRequestClient.get(
    `channel_details/changes/${channelId}/${type}`,
  );

  return data as FetchChannelDetailsTransitionResponse;
}

//most change 用の型を作る。

export async function fetchChannelListing(
  channelIds: string[],
): Promise<ChannelSearchListingResult> {
  const query = channelIds
    .map((id) => `channelId=${encodeURIComponent(id)}`)
    .join('&');
  const { data } = await apiRequestClient.get(
    `/channel_details/listing?${query}`,
  );

  return data;
}
