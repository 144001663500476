import styled from 'styled-components';
import { Back } from './SVG';
import { Button } from '@material-ui/core';

const BackButton = () => {
  return (
    <Container>
      <Button
        onClick={() => {
          history.back();
        }}
      >
        <Back />
      </Button>
    </Container>
  );
};

export default BackButton;

const Container = styled.div`
  display: flex;
  cursor: pointer;
  height: 100%;
  align-items: center;
`;
