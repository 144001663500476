import uniqBy from 'lodash/uniqBy';
import apiRequestClient from '../apiClient';
import { Bookmarks } from './userInfo.types';

export type FetchUserInfoResult = {
  channel_id: string;
  email: string;
  first_name: string;
  last_name: string;
  profile_img: string;
};

export type UserKeywordsResult = {
  keywords: { keyword: string }[];
};

export async function fetchUserInfo(): Promise<FetchUserInfoResult> {
  const { data } = await apiRequestClient.get('users/me');
  return {
    channel_id: data.channel_id,
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    profile_img: data.profile_img,
  };
}

export async function fetchChannelBookmarks() {
  const { data } = await apiRequestClient.get(`channel_bookmarks/`);

  return uniqBy(data as Bookmarks[], 'channel_id');
}

export async function addChannelBookmarks(channelId: string): Promise<void> {
  const { data } = await apiRequestClient.put(`channel_bookmarks/${channelId}`);
  return data;
}

export async function deleteChannelBookmarks(channelId: string): Promise<void> {
  const { data } = await apiRequestClient.delete(
    `channel_bookmarks/${channelId}`,
  );
  return data;
}

export async function fetchChannelKeyword(): Promise<UserKeywordsResult> {
  const { data } = await apiRequestClient.get(`channel_keyword/me`);
  return data;
}

export async function userWithdrawal(): Promise<void> {
  const { data } = await apiRequestClient.post(`users/withdraw`);
  console.log(data);
  return data;
}
