import { createContext, useContext, useState } from 'react';

type PageTitleContext = {
  title: string;
  headerTitle: string;
  setTitle: (value: string) => void;
  setHeaderTitle: (value: string) => void;
};

const context = createContext({} as PageTitleContext);

export const { Provider: PageTitleProvider } = context;

export default function usePageTitle() {
  const [title, setTitle] = useState('');
  const [headerTitle, setHeaderTitle] = useState('');

  const {
    title: _title,
    headerTitle: _headerTitle,
    setTitle: _setTitle,
    setHeaderTitle: _setHeaderTitle,
  } = useContext(context);

  return {
    contextValue: { title, headerTitle, setTitle, setHeaderTitle },
    title: _title,
    headerTitle: _headerTitle,
    setTitle: _setTitle,
    setHeaderTitle: _setHeaderTitle,
  };
}
