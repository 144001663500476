import { action, makeObservable, observable, runInAction } from 'mobx';
import { createContext } from 'react';
import { fetchUserInfo } from '../api/UserInfo/userInfo';
import { fetchChannelKpi } from '../api/ChannelDetails/channelDetails';
import { Selector, useStore } from './useStore';
import {
  fetchChannelBookmarks,
  addChannelBookmarks,
  deleteChannelBookmarks,
  fetchChannelKeyword,
} from '../api/UserInfo/userInfo';
import { Bookmarks } from '../api/UserInfo/userInfo.types';
import UserDefaultIcon from '../icons/ico/user_default.svg';

class UserInfoStore {
  @observable private _isLoading = true;

  @observable private _isLogin = false;

  @observable private _channelId = '';
  @observable private _channelTitle = '';
  @observable private _channelImg = '';

  @observable private _averageViewCount = 0;
  @observable private _subscriberCount = 0;
  @observable private _videoCount = 0;
  @observable private _averageCommentCount = 0;
  @observable private _viewCountChange = 0;
  @observable private _subscriberCountChange = 0;
  @observable private _averageDuration = 0;
  @observable private _averageVideoCount = 0;

  @observable private _profileImg = '';

  @observable private _email = '';
  @observable private _firstName = '';
  @observable private _lastName = '';

  @observable private _isLoadingBookmarks = false;
  @observable private _channelBookmarks: ChannelBookmarksType[] = [];
  @observable private _channelKeyword: string[] = [];

  constructor() {
    makeObservable(this);
  }

  public get isLoading() {
    return this._isLoading;
  }

  public get isLogin() {
    return this._isLogin;
  }

  public get channelId() {
    return this._channelId;
  }

  public get channelTitle() {
    return this._channelTitle;
  }

  public get channelImg() {
    return this._channelImg;
  }

  public get averageViewCount() {
    return this._averageViewCount;
  }

  public get subscriberCount() {
    return this._subscriberCount;
  }

  public get videoCount() {
    return this._videoCount;
  }

  public get averageCommentCount() {
    return this._averageCommentCount;
  }

  public get viewCountChange() {
    return this._viewCountChange;
  }

  public get subscriberCountChange() {
    return this._subscriberCountChange;
  }

  public get averageDuration() {
    return this._averageDuration;
  }

  public get averageVideoCount() {
    return this._averageVideoCount;
  }

  public get profileImg() {
    return this._profileImg || UserDefaultIcon;
  }

  public get email() {
    return this._email;
  }
  public get firstName() {
    return this._firstName;
  }
  public get lastName() {
    return this._lastName;
  }

  public get isLoadingBookmarks() {
    return this._isLoadingBookmarks;
  }

  public get channelBookmarks() {
    return this._channelBookmarks;
  }

  public get channelKeyword() {
    return this._channelKeyword;
  }

  @action.bound async fetchUserInfo() {
    const userInfo = await fetchUserInfo();
    this._isLogin = !!userInfo;
    this._channelId = userInfo.channel_id;
    this._email = userInfo.email;
    this._firstName = userInfo.first_name;
    this._lastName = userInfo.last_name;
    this._profileImg = userInfo.profile_img;
  }

  @action.bound async fetchUserChannelDetails(channelId: string) {
    this._channelId = channelId;
    if (this.channelId === '') {
      return;
    }
    const result = await fetchChannelKpi(channelId);
    runInAction(() => {
      this._channelTitle = result.channel_title;
      this._channelImg = result.channel_img;
      this._videoCount = result.video_count;
      this._averageViewCount = result.average_view_count;
      this._subscriberCount = result.subscriber_count;
      this._videoCount = result.video_count;
      this._averageDuration = result.average_duration ?? 0;
    });
  }

  @action.bound async fetchChannelBookmarks() {
    this._isLoadingBookmarks = false;
    const result = await fetchChannelBookmarks();
    const channelBookmarks = await Promise.all(
      result.map(this.bookmarksToStore),
    );
    runInAction(() => {
      this._isLoadingBookmarks = true;
      this._channelBookmarks = channelBookmarks;
    });
  }

  @action.bound async addChannelBookmarks(channelId: string) {
    await addChannelBookmarks(channelId);
    const result = await fetchChannelBookmarks();
    const channelBookmarks = await Promise.all(
      result.map(this.bookmarksToStore),
    );
    runInAction(() => {
      this._channelBookmarks = channelBookmarks;
    });
  }

  @action.bound async deleteChannelBookmarks(channelId: string) {
    await deleteChannelBookmarks(channelId);
    const result = await fetchChannelBookmarks();
    const channelBookmarks = await Promise.all(
      result.map(this.bookmarksToStore),
    );
    runInAction(() => {
      this._channelBookmarks = channelBookmarks;
    });
  }

  @action.bound async fetchChannelKeyword() {
    const result = await fetchChannelKeyword();
    const channelKeyword = result.keywords;
    runInAction(() => {
      this._channelKeyword = channelKeyword.map((channelKeyword) => {
        return channelKeyword.keyword;
      });
    });
  }

  private async bookmarksToStore(item: Bookmarks) {
    const details = await fetchChannelKpi(item.channel_id);
    return {
      channel_id: item.channel_id,
      label: details.channel_title,
      created_at: item.created_at,
      channel_img: details.channel_img,
      channel_title: details.channel_title,
      subscriber_count: details.subscriber_count,
      subscriber_count_change: details.subscriber_count_change,
      video_count: details.video_count,
      view_count_change: details.view_count_change,
      trend_video_count: details.trend_video_count,
    };
  }
}

let context: React.Context<UserInfoStore> | null = null;

export function useUserInfoStore<TSelection>(
  selector: Selector<UserInfoStore, TSelection>,
): TSelection {
  if (!context) {
    context = createContext<UserInfoStore>(new UserInfoStore());
  }
  return useStore(context, selector);
}

export type ChannelBookmarksType = {
  channel_id: string;
  label: string;
  created_at: Date;
  channel_img: string;
  channel_title: string;
  subscriber_count: number | null;
  subscriber_count_change: number | null;
  video_count: number | null;
  view_count_change: number | null;
  trend_video_count: number | null;
};
